import React from "react";

function Footer() {
    return (
        <footer className="w-full py-6 bg-gray-800 text-white">
            <div className="mx-auto w-full grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
                <div>
                    <h3 className="font-bold mb-2">About</h3>
                    <ul>
                        <li>
                            <a href="/ads" className="hover:underline">
                                Why do we show ads?
                            </a>
                        </li>
                        <li>
                            <a href="/donate" className="hover:underline">
                                Buy us a coffee
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className="font-bold mb-2">Connect</h3>
                    <ul>
                        <li>
                            <a href="mailto:anonymous@alienx.app" className="hover:underline">
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a href="/deleteAccount" className="hover:underline">
                                Delete Your Account
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className="font-bold mb-2">Legal</h3>
                    <ul>
                        <li>
                            <a href="/privacy_policy" className="hover:underline">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="/terms_of_service" className="hover:underline">
                                Terms of Service
                            </a>
                        </li>
                        <li>
                            <a href="/university_response" className="m-2 hover:underline">
                                Our View (App Discouragement)
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="text-center mt-4 text-sm text-gray-400">
                &copy; {new Date().getFullYear()} AlienX. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
