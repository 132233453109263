import "../style.css";
const PrivacyPolicy = () => {
  return (
    <div className="container-policy">
      <h1 className={"text-4xl"}>Privacy Policy for Collegia: Academics Manager</h1>
      <p>At AlienX, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by Collegia: Academics Manager app and how we use it. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
      <p>This Privacy Policy applies only to our online activities and is valid for our app regarding the information that they shared and/or collected in Collegia: Academics Manager app. This policy is not applicable to any information collected offline or via channels other than this application.</p>

      <h2 className={"text-2xl"} >Consent</h2>
      <p>By using our app, you hereby consent to our Privacy Policy and agree to its terms.</p>

      <h2 className={"text-2xl"}>Information we collect</h2>
      <p>The credentials that the users are asked to provide are used solely for the purpose of the app. If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide. When you register for an Account, we use Google account for authentication purposes and Collegia credentials for application functionality.</p>

      <h2 className={"text-2xl"}>How we use your information</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our app</li>
        <li>Develop new features and functionality</li>
      </ul>

      <h2 className={"text-2xl"}>Use of Google Calendar API</h2>
      <p>By using our app and granting permissions to access your Google Calendar, you agree to allow our application to retrieve, display, edit, delete and manage your calendar events and metadata as described above.</p>

      <h2 className={"text-2xl"}>Third Party Privacy Policies</h2>
      <p>Collegia: Academics Manager app use and transfer to any other app of information received from Google APIs will adhere to <a style={{ color: "rgb(0, 140, 255)" }} href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy</a>, including the Limited Use requirements. This does not apply to any other advertisers or websites. Thus, we advise you to consult the respective Privacy Policies of these</p>

      <h2 className={"text-2xl"}>Contact Us</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a className={"text-green-400"} href="mailto:anonymous@alienx.app">anonymous@alienx.app</a></p>

      <h2 className={"text-2xl"}>Security</h2>
      <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. We use multiple authentication techniques such as JWT token to make access of user data secure. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

      <h2 className={"text-2xl"}>Service Providers</h2>
      <p>We may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
    </div>
  );
};

const TermsOfService = () => {
    return (
        <div className="container-policy">
            <h1 className={"text-4xl"}>Terms & Conditions</h1>
            <p>
                By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trademarks, copyright, database rights and other intellectual property rights related to it, still belong to AlienX.
            </p>
            <p>
                AlienX is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason.
            </p>
            <p>
                The application needs the user's credentials of Chandigarh University Information Management System to function. The application stores the information provided to us by the user and the data retrieved using the credentials provided by the user on a cloud server and on the device itself. It’s your responsibility to keep your phone and access to the app secure. We are taking upmost measure to handle your data in secure manner.
            </p>
            <p>
                The app does use third-party services that declare their own <a className={"text-blue-500"} href="https://policies.google.com/terms">Terms and Conditions</a>:
            </p>
            <ul>
                <li>Google Play Services</li>
                <li>Google Analytics for Firebase</li>
                <li>Google AdMob</li>
            </ul>
            <p>
                You should be aware that there are certain things that AlienX will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but AlienX cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
            </p>
            <p>
                Changes to These Terms and Conditions:<br/>
                We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.<br/>
                These terms and conditions are effective as of 2021-09-28.
            </p>
            <p>
                Contact Us:<br/>
                If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at <a className={"text-green-400"} href="mailto:anonymous@alienx.app">anonymous@alienx.app</a>.
            </p>
        </div>
    );
};

export {PrivacyPolicy, TermsOfService}
