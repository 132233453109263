import React from "react";
// import {useEffect} from "react";

// // animation
// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

import data from "../data/data.json";
import Top from "./HomeComponents/Top";
import ProjectLayout from "./HomeComponents/ProjectLayout";
import Footer from "./HomeComponents/Footer";

// components

function Main() {
    return (
        <div>
            <div className="container">
                <Top />
                <div className="mb-10">
                    {Object.keys(data).map((key, index) => {
                        const project = data[key];
                        return (
                            <div className="module" id="projects">
                                <ProjectLayout
                                    key={index}
                                    name={project.name}
                                    description={project.description}
                                    img={project.img}
                                    links={project.links}
                                    extra_info={project.extra_info}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Main;
