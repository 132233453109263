import React from "react";

const UniversityResponse = () => {
    return (
        <>
            <div className="text-center h-screen text-white flex">
                <p className="h-max mx-6 sm:mx-16 md:mx-36 flex flex-col justify-center">
                <h1 className="text-3xl md:text-5xl mb-6 font-medium">
                    <br/>
                    Our View on University App discouragement</h1>
                <div className="">
                    Respected Chandigarh University Administration and Fellow Students,
<br/><br/><br/>
We hope this message finds you well. Being dedicated students of Chandigarh University, We have invested significant time and effort over the past four years to develop the Academics Manager app for the university students only (not staff). Our primary focus throughout the development process has been to ensure the security and privacy of user data, implementing features such as Google Sign-In and Apple Sign-In for enhanced security.
<br/><br/><br/>
Recently, We learned that the university has issued a notice regarding the usage of unauthorized mobile applications related to Collegia, specifically mentioning our Academics Manager app. It was disheartening to read the notice discouraging the use of the app without any prior communication or acknowledgment of the effort and dedication invested in its development from the students of CU.
<br/><br/><br/>
We would like to express our willingness to discuss and address any concerns the university may have regarding the app's security or functionality. We have taken every precaution to make sure that the app adheres to the highest standards of security, with a strong emphasis on protecting the confidentiality and privacy of sensitive information. There are security rules setup in our services per user level. We don't allow staff to use our app. This app is solely built for students. In addition to that, app is just a wrapper around the CUIMS website, so every request is being called in the same manner as CUIMS website. We are just making it faster and better with our own techniques, algorithms and caching.
<br/><br/>
We are open to collaborating with the university to ensure that the Academics Manager app meets all necessary criteria and security standards (which university wants). Our goal has always been to contribute positively to the university community, and We believe that open communication and collaboration can lead to a better understanding of the app's purpose and functionalities.
<br/><br/>
We kindly request an opportunity to engage in a conversation with the university administration to clarify any misunderstandings and to work together in order to address any concerns. We believe that by working collaboratively, we can find a solution that benefits the entire university community.
<br/><br/><br/>
Thank you for your understanding and consideration.
<br/><br/><br/>
Sincerely,
<br/><br/>
Sawan Sihag, Rahul Saini
<br/><br/>
<a
                    href="mailto:anonymous@alienx.app"
                    className="m-2 hover:underline"
                >
                    Mail us at anonymous@alienx.app
                </a>
                    <br/>
                    <br/>
                </div>
                </p>
            </div>
        </>
    );
};

export default UniversityResponse;
